import React, { cloneElement, useState, useEffect, useCallback } from "react";
// antd
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
// components
import FormAttach from "./Form";

function DialogAdd({ children, mutation, state }) {
  const { error, data, loading } = state;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const onClick = useCallback(() => setVisible(!visible), [visible]);

  useEffect(() => {
    if (!visible) form.resetFields();
  }, [visible, form]);

  useEffect(() => {
    if (!loading && data && !error) onClick();
  }, [state, loading, data, error, onClick]);

  const props = {
    Form: {
      form,
      name: "Attach",
      layout: "vertical",
      onFinish: mutation,
      hideRequiredMark: true,
    },
    modal: {
      visible,
      onOk: form.submit,
      title: "Subir examen médico",
      onCancel: onClick,
      destroyOnClose: true,
      confirmLoading: loading,
    },
  };
  return (
    <>
      {cloneElement(children, { onClick })}
      <Modal {...props.modal}>
        <FormAttach {...props.Form} />
      </Modal>
    </>
  );
}

export default DialogAdd;
