import React from "react";
import Modal from "antd/lib/modal";
import ContendModal from "./ContendModal";

const ModalWizard = (props) => {
  const { visbleModal, onClose } = props;
  return (
    <Modal
      destroyOnClose
      style={{ minWidth: "20rem" }}
      bodyStyle={{ paddingBottom: "0", minHeight: "500px" }}
      className='w-9/12 md:w-10/12 lg:w-6/12'
      // width='50vw'
      title={null}
      footer={null}
      visible={visbleModal}
      onOk={() => console.log("sumit ok")}
      onCancel={() => onClose()}
    >
      <ContendModal onClose={onClose} />
    </Modal>
  );
};

export default ModalWizard;
