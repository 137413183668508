import React, { useState, useContext, useEffect } from "react";
import Button from "antd/lib/button";
import notification from "antd/lib/notification";
import ModalWizard from "./ModalWizard";
import { faLaptopMedical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalContext } from "views/App";
import * as actionName from "../../store/actions";
import { HandleError } from "utils.js/HandleError";

import { useHistory } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { meByAppointmentIC } from "./gqlWizard";
import { URLDOCTORIC, URLDOCTORMDIC } from "../../chatCall/utils";

import get from "lodash/get";

const WizardMD = (_) => {
  const { classNameButton = "", classNameIcon = "" } = _;
  const history = useHistory();
  const [visbleModal, setVisbleModal] = useState(false);
  const { state: globalState, dispatch: dispatchGlobal } = useContext(GlobalContext);
  const { user } = globalState;
  const role = get(user, "role.name");

  const Appointments = () => {
    const lazyQueryAppoinmet = useLazyQuery(meByAppointmentIC, {
      fetchPolicy: "no-cache",
    });

    const queryAppoinment = useQuery(meByAppointmentIC, {
      fetchPolicy: "no-cache",
      pollInterval: 6000,
    });

    return [lazyQueryAppoinmet, queryAppoinment];
  };

  const [[fetchAppointmentIC, { loading, error, data }], { data: dataAppointmentIC }] =
    Appointments();

  useEffect(() => {
    if (data) {
      if (data.me.appointmentIC) {
        history.push(
          `${role === "doctor" ? URLDOCTORIC : URLDOCTORMDIC}` + data.me.appointmentIC.id
        );
      } else {
        dispatchGlobal({
          type: actionName.SET_USER,
          payload: {
            ...user,
            appointmentIC: data.me.appointmentIC,
          },
        });
        setVisbleModal(true);
      }
    }
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [data, loading, error, dispatchGlobal, history, user, role]);

  const redirectValidationWizard = () => {
    try {
      if (user) {
        if (dataAppointmentIC?.me?.appointmentIC) {
          fetchAppointmentIC();
        } else {
          setVisbleModal(true);
        }
      } else {
        setVisbleModal(true);
      }
    } catch (e) {
      console.log(e);
      setVisbleModal(false);
    }
  };

  if (user && role !== "doctor" && role !== "doctorMD") {
    return null;
  }

  const props = {
    Button: {
      loading: loading,
      disabled: loading,
      onClick: () => redirectValidationWizard(),
      icon: (
        <FontAwesomeIcon className={classNameIcon + " mx-2"} size='sm' icon={faLaptopMedical} />
      ),
      type: "default",
      shape: "round",
      size: "large",
      className: `button-success ${
        classNameButton || "block lg:-mt-1 mt-3 xs:mx-auto sm:ml-auto sm:mr-auto md:mx-4 md:ml-0"
      } `,
    },
  };

  return (
    <>
      <ModalWizard user={user} visbleModal={visbleModal} onClose={() => setVisbleModal(false)} />
      {user && dataAppointmentIC?.me?.appointmentIC ? (
        <Button {...props.Button}>
          <span className='break-words'>Ir a la sala de espera</span>
        </Button>
      ) : role === "doctorMD" ? (
        <Button {...props.Button}>Inter-Consulta</Button>
      ) : null}
    </>
  );
};

export default React.memo(WizardMD);
