import React from "react";
// antd
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import Select from "antd/lib/select";
import DatePicker from "../InputDayPicker";
// components
import InputNumeric from "components/InputNumeric";
// icons
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faPhone,
  faUserAlt,
  faWeight,
  faRulerCombined,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const { Option } = Select;

export const typeBLOOD = [
  {
    label: "A-",
    value: "A_MINUS",
  },
  {
    label: "A+",
    value: "A_PLUS",
  },
  {
    label: "B-",
    value: "B_MINUS",
  },
  {
    label: "B+",
    value: "B_PLUS",
  },
  {
    label: "O-",
    value: "O_MINUS",
  },
  {
    label: "O+",
    value: "O_PLUS",
  },
  {
    label: "AB-",
    value: "AB_MINUS",
  },
  {
    label: "AB+",
    value: "AB_PLUS",
  },
];

export default {
  name: {
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Por favor ingrese su primer nombre!",
      },
    ],
    children: <Input placeholder='Primer Nombre' prefix={<Fa icon={faUserAlt} />} />,
  },
  lastName: {
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Por favor ingrese su primer apellido!",
      },
    ],
    children: <Input placeholder='Primer Apellido' prefix={<Fa icon={faUserAlt} />} />,
  },
  email: {
    name: "email",
    rules: [
      {
        type: "email",
        message: "Por favor ingrese un correo electronico valido!",
      },
    ],
    children: <Input placeholder='Correo electronico' prefix={<Fa icon={faEnvelope} />} />,
  },
  birthday: (isMain) => ({
    name: "dateOfBirth",
    children: (
      <DatePicker
        format='D-MM-YYYY'
        allowClear={false}
        placeholder='Fecha de nacimiento'
        prefix={<Fa icon={faBirthdayCake} />}
        disabledDate={(current) => {
          const currentDate = moment().subtract(18, "years").format("D-MM-YYYY");
          return !isMain && current && current < moment(currentDate, "D-MM-YYYY");
        }}
      />
    ),
  }),
  phone: {
    name: "phoneNumber",
    children: <InputNumeric placeholder='Número de teléfono' prefix={<Fa icon={faPhone} />} />,
  },
  height: {
    name: "height",
    children: (
      <InputNumeric suffix='metros' placeholder='Altura' prefix={<Fa icon={faRulerCombined} />} />
    ),
  },
  weight: {
    name: "weight",
    children: <InputNumeric suffix='libras' placeholder='Peso' prefix={<Fa icon={faWeight} />} />,
  },
  typeOfBlood: {
    name: "typeOfBlood",
    children: (
      <Select placeholder='Tipo de sangre'>
        {typeBLOOD.map((item, index) => (
          <Option key={index} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    ),
  },
  sex: {
    name: "sex",
    children: (
      <Radio.Group className='flex flex-col'>
        <Radio value='MALE'>Masculino</Radio>
        <Radio value='FEMALE'>Femenino</Radio>
        <Radio value='OTHER'>Otro</Radio>
      </Radio.Group>
    ),
  },
};
