import React from "react";
// antd
import Avatar from "antd/lib/avatar";
// icons
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AvatarPerson(props) {
  return <Avatar {...props} />;
}

AvatarPerson.defaultProps = {
  size: 68,
  icon: <FontAwesomeIcon icon={faUser} />,
  shape: "circle",
  style: {
    color: "#29AAE1",
    backgroundColor: "white",
  },
};

export default AvatarPerson;
