import gql from "graphql-tag";

export const symptomsListGql = () => {
  return gql`
    query symptoms($page: Float, $perPage: Float, $filters: [symptomFilter!]) {
      symptoms(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

export const medicalconditionsWizard = () => {
  return gql`
    query medicalconditionsWizard(
      $page: Float
      $perPage: Float
      $filters: [medicalconditionFilter!]
    ) {
      medicalconditions(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

export const meByAppointmentMD = gql`
  query meByAppointmentMD {
    me {
      id
      fullName
      appointmentMD {
        id
        reasonForConsultation
      }
    }
  }
`;

export const patientByIdWizardMD = gql`
  query patientByIdWizardMD($id: String!) {
    patientById(id: $id) {
      id
      fullName
      newDrugAllergies
      newMedicalConditions
    }
  }
`;
export const alergysWizardMD = () => {
  return gql`
    query drugs($page: Float, $perPage: Float, $filters: [drugFilter!]) {
      drugs(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

export const createAppointmentMD = gql`
  mutation createAppointmentMD(
    $appointmentSpecialty: AppointmentSpecialty!
    $newSymptoms: [String!]
    $reasonForConsultation: String
    $appointmentType: AppointmentType!
    $patient: String!
    $newDrugAllergies: [String!]
    $newMedicalConditions: [String!]
  ) {
    createAppointmentMD(
      appointmentSpecialty: $appointmentSpecialty
      newSymptoms: $newSymptoms
      reasonForConsultation: $reasonForConsultation
      appointmentType: $appointmentType
      patient: $patient
      newDrugAllergies: $newDrugAllergies
      newMedicalConditions: $newMedicalConditions
    ) {
      id
      reasonForConsultation
    }
  }
`;
