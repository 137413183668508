import React from "react";
import { Link } from "react-router-dom";
import logoEasy from "assets/img/bioanalisisLogo.png";

export default function Brand() {
  return (
    <Link to='/md/home' className='brand'>
      <img
        style={{
          width: "240px",
          height: "50px",
          objectFit: "contain",
        }}
        src={logoEasy}
        alt='LogoEasy'
      />
    </Link>
  );
}
