import { Col, Row, Spin } from "antd";
import React from "react";

const BrandLoading = (props) => {
  return (
    <>
      <div className={props.cssClass || "w-screen h-screen flex justify-center items-center"}>
        <div className='flex justify-center items-center w-full h-full'>
          <Row>
            <Col span={24} className='flex justify-center items-center'>
              <Spin size='large' />
            </Col>
            <Col span={24} className='flex justify-center items-center mt-1'>
              <h1 className='text-xl text-primary'>Cargando...</h1>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

BrandLoading.defaultProps = {
  cssClass: "w-screen h-screen flex justify-center items-center",
};

export default BrandLoading;
