// import Cookies from 'js-cookie'
// import mapKeys from 'lodash/mapKeys'

import { logoutUser } from "chatCall/utils";

const removeSession = () => {
  sessionStorage.clear();
};

const removeLocal = () => {
  localStorage.clear();
};

export const logoutAndClearAll = () => {
  logoutUser();
  removeLocal();
  removeSession();
};

export const logoutNormal = () => {
  logoutUser();
  removeLocal();
};
