import React from "react";
import { Col, Spin, Row } from "antd";

export default () => (
  <div className='flex justify-center items-center w-screen h-screen'>
    <Row>
      <Col span={24} className='flex justify-center items-center'>
        <Spin size='large' />
      </Col>
      <Col span={24} className='flex justify-center items-center mt-1'>
        <h1 className='text-xl text-primary'>Cargando...</h1>
      </Col>
    </Row>
  </div>
);
