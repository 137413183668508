import React from "react";
import Layout from "antd/lib/layout";

// logo
import Bottom from "layouts/site/footer/Bottom";
import ContactInfo from "layouts/site/footer/ContactInfo";

export default function Footer() {
  return (
    <Layout.Footer className='flex flex-col bg-primary pt-6 px-4 md:pb-0' id='FooteEasyMD'>
      <div className='container mx-auto'>
        <div className='grid grid-flow-cols gap-4 md:grid-cols-2'>
          <ContactInfo />
        </div>
        <Bottom />
      </div>
    </Layout.Footer>
  );
}
