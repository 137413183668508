import React from "react";
// antd
import Input from "antd/lib/input";
// helpers
import toNumber from "lodash/toNumber";
import toString from "lodash/toString";

export default class NumericInput extends React.Component {
  onChange = ({ target }) => {
    const { onChange } = this.props;
    const { value } = target;

    const reg = /^-?\d*(\.\d*)?$/;

    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-")
      if (onChange) onChange(value);
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value: _val, onBlur, onChange } = this.props;

    let value = toString(_val);
    let valueTemp = value;

    if (value.charAt(value.length - 1) === "." || value === "-") valueTemp = value.slice(0, -1);

    if (onChange) onChange(toNumber(valueTemp.replace(/0*(\d+)/, "$1")));
    if (onBlur) onBlur();
  };

  render() {
    return (
      <Input
        {...this.props}
        defaultValue={this.props._val}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    );
  }
}
