import gql from "graphql-tag";

export const symptomsListGql = () => {
  return gql`
    query symptoms($page: Float, $perPage: Float, $filters: [symptomFilter!]) {
      symptoms(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

export const medicalconditionsWizard = () => {
  return gql`
    query medicalconditionsWizard(
      $page: Float
      $perPage: Float
      $filters: [medicalconditionFilter!]
    ) {
      medicalconditions(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

export const meByAppointmentIC = gql`
  query meByAppointmentIC {
    me {
      id
      fullName
      appointmentIC {
        id
        reasonForConsultation
        status
      }
    }
  }
`;

export const patientByIdWizardIC = gql`
  query patientByIdWizardIC($id: String!) {
    getPatientById(id: $id) {
      id
      fullName
      newDrugAllergies
      newMedicalConditions
    }
  }
`;
export const alergysWizardMD = () => {
  return gql`
    query drugs($page: Float, $perPage: Float, $filters: [drugFilter!]) {
      drugs(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

export const createAppointmentIC = gql`
  mutation createAppointmentIC(
    $newSymptoms: [String!]
    $reasonForConsultation: String
    $appointmentICType: AppointmentICType!
    $patient: String!
    $doctor: String!
    $doctorMD: String!
    $newDrugAllergies: [String!]
    $newMedicalConditions: [String!]
  ) {
    createAppointmentIC(
      newSymptoms: $newSymptoms
      reasonForConsultation: $reasonForConsultation
      appointmentICType: $appointmentICType
      patient: $patient
      doctor: $doctor
      doctorMD: $doctorMD
      newDrugAllergies: $newDrugAllergies
      newMedicalConditions: $newMedicalConditions
    ) {
      id
      reasonForConsultation
    }
  }
`;

export const patientListGql = gql`
  query usersFromCompany(
    $name: String!
    $perPage: Float
    $page: Float
    $patientFilter: [companyPatientFilterCustomInput!]
  ) {
    usersFromCompany(name: $name, perPage: $perPage, page: $page, patientFilter: $patientFilter) {
      total
      pages
      docs {
        id
        pictureURL {
          id
          url
        }
        fullName
        email
        verified
        phoneNumber
      }
    }
  }
`;

export const doctorListGql = gql`
  query doctors($perPage: Float, $page: Float, $filters: [doctorFilter!]) {
    doctors(perPage: $perPage, page: $page, filters: $filters) {
      total
      pages
      docs {
        id
        pictureURL {
          id
          url
        }
        fullName
        phoneNumber
        email
        minsaNumber
        specialties {
          id
          name
        }
      }
    }
  }
`;
