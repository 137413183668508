export const statusName = (status) => {
  switch (status) {
    case "PENDING":
      return {
        className: "bg-warning",
        color: "#F9BF00",
        label: "Pendiente",
        type: "warning",
        labelCalendar: "Agendando...",
      };
    case "SCHEDULED":
      return {
        className: "bg-success",
        color: "#8BC53F",
        label: "Agendada",
        type: "success",
        labelCalendar: "Agendanda",
      };
    case "ONGOING":
      return {
        className: "bg-info",
        color: "#B6E6FB",
        label: "En Curso",
        type: "info",
        labelCalendar: "En consulta",
      };
    case "COMPLETE":
      return {
        className: "bg-primary",
        color: "#29AAE1",
        label: "Completada",
        type: "primary",
        labelCalendar: "Finalizada",
      };
    case "CANCELED":
      return {
        className: "bg-danger",
        color: "#DB5151",
        label: "Cancelada",
        type: "danger",
        labelCalendar: "Cancelada",
      };
    default:
      return { color: "", label: "" };
  }
};

export const statusUsable = (status) => {
  switch (status) {
    case "NOT_SCHEDULED":
      return {
        color: "button-warning",
        label: "Continuar agendando",
        labelDoctor: "Paciente agendando",
        disabledDoctor: true,
        disabled: false,
      };
    case "BEFORE_APPOINTMENT":
      return {
        color: "button-success",
        label: "Ir a la sala de espera",
        labelDoctor: "Ir a la sala de espera",
        disabledDoctor: false,
        disabled: false,
      };
    case "IN_APPOINTMENT":
      return {
        color: "button-success",
        label: "Entrar a la consulta",
        labelDoctor: "Entrar a la consulta",
        disabledDoctor: false,
        disabled: false,
      };
    case "AFTER_APPOINTMENT":
      return {
        color: "primary",
        label: "Esperando diagnostico",
        labelDoctor: "Editar diagnostico",
        disabledDoctor: false,
        disabled: true,
      };

    default:
      return {
        color: "",
        label: status,
        labelDoctor: status,
        disabledDoctor: true,
        disabled: true,
      };
  }
};

export const approvalStatusDoctor = (approvalStatus) => {
  switch (approvalStatus) {
    case "DOCUMENTS_REQUIRED":
      return {
        color: "#F9BF00",
        label:
          '¡Hola! para finalizar tu perfil has click en "INF. ADICIONAL" y adjunta tus credenciales.',
        type: "warning",
        typeAlert: "warning",
      };

    case "UNDER_REVIEW":
      return {
        color: "#29AAE1",
        label:
          "Su aplicación está siendo aprobada por administración, este proceso puede tardar entre 24 a 48 horas!",
        type: "primary",
        typeAlert: "info",
      };
    case "APPROVED":
      return {
        color: "#8BC53F",
        label: "Lorem ipsum APPROVED",
        type: "success",
        typeAlert: "success",
      };
    case "DENIED":
      return {
        color: "#DB5151",
        label:
          "Hola, se encontró una discrepancia en la información enviada, por favor ir a botón de ayuda y recibir apoyo",
        type: "danger",
        typeAlert: "error",
      };

    default:
      return {
        color: "",
        label: approvalStatus,
        type: "",
      };
  }
};
