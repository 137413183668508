import React, { useEffect, useContext } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { GlobalContext } from "views/App";
import HomeLayout from "./site";
import ProfileLayout from "../layouts/profile";
import VideoCallLayout from "../layouts/videoCall";
import CallChatLayout from "../chatCall/ChatCallLayout";
import AutoLogin from "./autoLogin/AutoLogin";

const RouteConfig = ({ history, location }) => {
  const { state } = useContext(GlobalContext);
  const { user } = state;
  const url = location.pathname;

  useEffect(() => {
    history.listen(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
  }, [history]);

  useEffect(() => {
    if (user && url.includes("login", "signup")) {
      history.push("/patient");
    } else if (url === "/") {
      history.push("/login");
    }
  }, [user, url, history]);

  return (
    <Switch>
      <CallChatLayout>
        <Route path='/autoLogin' render={(props) => <AutoLogin {...props} />} />
        <Route path='/login' render={(props) => <HomeLayout {...props} />} />
        <Route path='/patient' render={(props) => <ProfileLayout {...props} />} />
        <Route path='/cita' render={(props) => <VideoCallLayout {...props} />} />
      </CallChatLayout>
    </Switch>
  );
};

export default withRouter(RouteConfig);
