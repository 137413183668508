import React from "react";
import RowAvatar from "components/RowAvatar";
import { Popover, Tag } from "antd";

const columns = (loading) => {
  return [
    {
      title: "Nombre",
      dataIndex: "fullName",
      render: (text, row) => <RowAvatar text={text} row={row} />,
    },
    {
      title: "Especialidad",
      dataIndex: "specialties",
      className: "text-center w-2/6",
      render: (row, fullRow) => renderSpecialties(row, fullRow),
    },
    {
      title: "Correo Electronico",
      dataIndex: "email",
      className: "text-right w-1/6",
      width: 180,
      render: (row) => <p className='text-right pb-1 mb-1'>{row}</p>,
    },
  ];
};

const renderSpecialties = (row, fullRow) => {
  if (row) {
    if (row.length > 1) {
      return (
        <Popover
          content={
            <div className='w-full flex flex-col justify-center items-center'>
              <p className='font-semibold text-center'>{fullRow.fullName}</p>
              <ul>
                {row.map((value, index) => {
                  return <li key={index}>{value.name}</li>;
                })}
              </ul>
            </div>
          }
        >
          <div className='flex flex-row justify-center items-center'>
            <span>{row[0].name},</span>
            <Tag
              color='blue'
              className='ml-1 flex flex-row justify-center content-center rounded-lg px-1'
            >
              <span className='text-2xl self-center'>+</span>
              {row.length - 1}
            </Tag>
          </div>
        </Popover>
      );
    } else if (row.length === 1) {
      return <span>{row[0].name}</span>;
    }
  }
  return "N/A";
};
export default columns;
