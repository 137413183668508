import React from "react";
// antd
import Card from "antd/lib/card";
// components
import Avatar from "components/User/Avatar";

function Medic({ pic, name }) {
  const props = {
    Card: {
      size: "small",
      bodyStyle: {
        padding: "4px 0",
        overflow: "hidden",
      },
      className: "rounded-full mb-3",
    },
    Meta: {
      title: name,
      avatar: <Avatar size={48} src={pic} />,
      className: "flex items-center",
    },
  };

  return (
    <Card {...props.Card}>
      <Card.Meta {...props.Meta} />
    </Card>
  );
}

export default Medic;
