export const GET_STEPS = "GETSTEPS";
export const SET_SPECIALTYACTIVE = "SETSPECIALTYACTIVE";
export const SET_SYMPTOMS = "SETSYMPTOMS";
export const SET_REASON_APPOINTMENTS = "SETREASONAPPOINTMENTS";
export const SET_STEPS = "SETSTEPS";
export const SET_FINISHWIZARD = "SETFINISHWIZARD";
export const SET_APPOINTMENTS_METHOD = "SETAPPOINTMENTSMETHOD";
export const SET_DRUGALLERGIES = "SETDRUGALLERGIES";
export const SET_MEDICALCONDITIONS = "SETMEDICALCONDITIONS";
export const SET_LOADINGGLOBAL = "SET_LOADINGGLOBAL";
