import React from "react";
// antd
import Layout from "antd/lib/layout";
// components
import Brand from "layouts/profile/Brand";

import WizardMD from "components/WizardMD";
import WizardInterconsulta from "components/WizardInterconsulta";
// context
import Context from "./Context";
// stiles
import style from "./style.module.scss";
import { Col, Row } from "antd";
import FooterHome from "layouts/site/footer";

export default function CommonLayout({ person, sider, content }) {
  return (
    <Context.Provider>
      <Layout>
        <Layout.Header className={style.header} style={{ background: "transparent" }}>
          <Brand />
          {person}
        </Layout.Header>
        <Layout.Content className='px-3' style={{ minHeight: "80vh" }}>
          <Row justify='start' align='middle'>
            <Col xs={24} sm={24} md={12} lg={8}>
              {sider}
            </Col>
            <Col sm={24} lg={12} md={12}>
              <Row justify='end'>
                <WizardMD classNameButton='block xl:hidden' />
                <WizardInterconsulta classNameButton='block xl:hidden' />
              </Row>
            </Col>
          </Row>
          {content}
        </Layout.Content>
        <FooterHome />
      </Layout>
    </Context.Provider>
  );
}
