import React from "react";
// components
import InputWordCloud from "components/InputWordCloud";
// gql
import { getMedicalConditions, getDrugs } from "../gql";

const props = {
  drugs: {
    gql: getDrugs,
    path: "drugs.docs",
    placeholder: "Alergias",
  },
  condition: {
    gql: getMedicalConditions,
    path: "medicalconditions.docs",
    placeholder: "Condiciones Médicas",
  },
};

export default {
  conditions: {
    name: "newMedicalConditions",
    children: <InputWordCloud {...props.condition} />,
  },
  allergies: {
    name: "newDrugAllergies",
    children: <InputWordCloud {...props.drugs} />,
  },
};
