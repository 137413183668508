import React, { useEffect, createRef, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Form, Button, notification, Alert } from "antd";

// componets
import InputForm from "./components/InputForm";
import InputList from "./InputList";
import InputCheck from "./components/InputCheck";

import { animations } from "react-animation";
import "react-animation/dist/keyframes.css";
import { HOST, LOCAL_HOST } from "utils.js/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { HandleError } from "utils.js/HandleError";

const SITEKEY = process.env.REACT_APP_RECAPTCHA;

const FormSignUp = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const recaptchaRef = createRef();
  const { hashInfo } = props;
  const [isNicaragua, setIsNicaragua] = useState(false);

  useEffect(() => {
    if (hashInfo || isNicaragua) {
      form.setFields([
        {
          ...(hashInfo && {
            name: "email",
            value: hashInfo.email,
            errors: null,
          }),
          ...(isNicaragua && { name: "nationality", value: "Nicaragua", error: null }),
        },
      ]);
    }
  }, [hashInfo, isNicaragua, form]);

  useEffect(() => {
    if (props.error && !props.data && !props.loading) {
      notification.destroy();
      notification.error({
        message: "Error",
        description: HandleError(props.error),
      });
    }
    if (props.data) {
      notification.success({
        message: "Usuario Creado",
      });
      if (props.redirect) {
        setTimeout(() => {
          history.push("/md/auth/login");
        }, 4000);
      }
    }
  }, [history, props]);

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(comparePosition);
    }
    function comparePosition(position) {
      const { coords } = position;
      const latitude = Number(String(coords.latitude).slice(0, 2));
      const longitude = Number(String(coords.longitude).slice(0, 3));
      if (latitude >= 11 && latitude <= 14 && longitude <= -84 && longitude >= -87)
        setIsNicaragua((_) => true);
    }
  }, []);
  useEffect(() => {
    getLocation();
  }, [getLocation]);

  const setTermsAndConditions = () => {
    form.setFields([
      {
        name: "termsAndConditions",
        value: true,
        errors: null,
      },
    ]);
  };

  const validateOnFinish = (values) => {
    form.validateFields().then((values) => {
      props.onFinish(values);
    });
  };

  const onValidateWithReCAPTCHA = async (values) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      validateOnFinish(values);
    }
  };

  return (
    <div
      style={{ animation: animations.fadeIn, duration: 1000 }}
      className='w-full lg:w-full form-create'
    >
      <h3 className='my-0 text-2xl'>
        <strong>{props.title}</strong>
      </h3>
      <p className='mb-3'>{props.description}</p>
      {isNicaragua && (
        <Alert
          className='mb-3'
          message={null}
          description='Si activastes tu Plan enviando SALUD al 400, por favor digita tu número de celular para activar tu plan BioAnalisis-Tigo.'
          showIcon
          closable={false}
          type='info'
        />
      )}
      <Form
        form={form}
        layout='horizontal'
        validateTrigger='onBlur'
        name='basic'
        initialValues={{ remember: true }}
        onFinish={(valueForm) => {
          HOST === LOCAL_HOST ? validateOnFinish(valueForm) : onValidateWithReCAPTCHA(valueForm);
        }}
      >
        <div className='grid grid-cols-12 gap-2'>
          <div className='col-span-12 md:col-span-6 lg:col-span-6'>
            <InputForm
              item={{ ...InputList.firstName.item }}
              input={{
                ...InputList.firstName.input,
                disabled: !!(props.loading || props.data),
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6 lg:col-span-6'>
            <InputForm
              item={{ ...InputList.lastName.item }}
              input={{
                ...InputList.lastName.input,
                disabled: !!(props.loading || props.data),
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              item={{ ...InputList.email.item }}
              input={{
                ...InputList.email.input,
                disabled: !!(props.loading || props.data || hashInfo),
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              item={{ ...InputList.phoneNumber.item }}
              input={{
                ...InputList.phoneNumber.input,
                disabled: !!(props.loading || props.data),
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              typeInput='password'
              item={{ ...InputList.password.item }}
              input={{
                ...InputList.password.input,
                disabled: !!(props.loading || props.data),
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              typeInput='password'
              seeTooltip={false}
              item={{ ...InputList.confirmPassword.item }}
              input={{
                ...InputList.confirmPassword.input,
                disabled: !!(props.loading || props.data),
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              typeInput='select'
              seeTooltip={false}
              item={{ ...InputList.nationality.item }}
              select={{
                ...InputList.nationality.select,
                disabled: !!(props.loading || props.data),
              }}
              options={["Nicaragua", "Panama"]}
            />
          </div>
          <div className='col-span-12 flex flex-col md:flex-row'>
            <div className='flex flex-col'>
              <div className='col-span-6'>
                <InputCheck
                  item={{ ...InputList.termsAndConditions.item }}
                  checkbox={{ disabled: !!(props.loading || props.data) }}
                  setFields={() => setTermsAndConditions()}
                  button={{
                    ...InputList.termsAndConditions.button,
                    disabled: !!(props.loading || props.data),
                    loading: props.loading,
                  }}
                />
              </div>
              <div className='flex xs:flex-col md:flex-row justify-between col-span-6'>
                <Form.Item className=''>
                  <Button
                    disabled={props.loading || props.data}
                    loading={props.loading}
                    htmlType='submit'
                    type='primary'
                    shape='round'
                    size='large'
                    className='xs:mx-auto md:mx-4'
                  >
                    Crear cuenta
                  </Button>
                </Form.Item>

                {props.buttonLogin && (
                  <Form.Item>
                    <Button
                      disabled={props.loading || props.data}
                      loading={props.loading}
                      onClick={() => props.clickLoginButton()}
                      type='default'
                      shape='round'
                      className='button-success xs:mx-auto md:mx-4'
                      size='large'
                    >
                      Ya tengo una cuenta
                    </Button>
                  </Form.Item>
                )}
              </div>
            </div>
            <ReCAPTCHA
              className='flex justify-center lg:justify-end lg:col-start-2 lg:col-span-3 md:ml-auto'
              badge='inline'
              ref={recaptchaRef}
              sitekey={SITEKEY}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

FormSignUp.defaultProps = {
  buttonLogin: false,
  redirect: true,
  clickLoginButton: () => {},
};

export default React.memo(FormSignUp);
