import React from "react";
// antd
import Menu from "antd/lib/menu";
// menu items
import menuItems from "./menuItems";
// helpers
import filter from "lodash/filter";
import includes from "lodash/includes";
export default function UserContextMenu(action, user) {
  const { name: rol, usedFor: group } = user.role || {};

  let uri = "";
  if (rol === "doctor" || rol === "doctorMD") {
    uri = "/medic";
  } else {
    uri = "/patient";
  }

  const onClick = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case "logout":
        return action.onLogout();
      case "profile":
        return action.goTo(uri);
      case "appointment":
        return action.goTo({
          pathname: uri,
          tab: "appointments",
        });
      case "dashboard": {
        if (rol === "company") {
          return action.goTo("/company");
        } else {
          return action.goTo("/admin");
        }
      }
    }
  };

  const items = filter(menuItems, ({ role }) => includes(role, rol) || includes(role, group));

  const propsMenu = {
    onClick,
    children: items.map((e, key) =>
      e.key === "logout" ? [<Menu.Divider key={key} />, <Menu.Item {...e} />] : <Menu.Item {...e} />
    ),
  };

  return <Menu {...propsMenu} />;
}
