import React, { useState, useContext, useEffect } from "react";
import CardRadio from "../Component/CardRadio";

import { wizardContext } from "../ModalWizard/ContendModal";
import * as actionName from "../Store/actionStore";

// icons Custom
import doctorIcon from "assets/customIcons/consulta.svg";
import doctorColorIcon from "assets/customIcons/consulta-hover.svg";
import brainIcon from "assets/customIcons/Psicologia.svg";
import brainColorIcon from "assets/customIcons/Psicologia-hover.svg";

const Steps1 = () => {
  const [focusButton, setFocusButton] = useState(null);
  const { state, dispatch } = useContext(wizardContext);

  useEffect(() => {
    setFocusButton(state.specialtyActive);
  }, [state.specialtyActive]);

  const setSpecialty = (payload) => {
    return dispatch({
      type: actionName.SET_SPECIALTYACTIVE,
      payload: payload,
    });
  };

  return (
    <>
      <div className='flex justify-center w-full mt-10 mb-8'>
        <h3 style={{ color: "#707070" }} className='text-xl'>
          Tu consulta requiere a un especialista
        </h3>
      </div>
      <CardRadio
        focusButton={focusButton}
        propsCard={[
          {
            title: "Medicina General",
            iconSrc: [doctorIcon, doctorColorIcon],
            key: "GENERAL",
          },
          {
            title: "Psicología",
            iconSrc: [brainIcon, brainColorIcon],
            key: "PSYCHOLOGY",
          },
        ]}
        onClickCard={(NewSpecialty) => setSpecialty(NewSpecialty)}
      />
    </>
  );
};

export default Steps1;
