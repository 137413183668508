import React from "react";
import { Row, Col, Spin } from "antd";
export default function Loading() {
  return (
    <div className='flex justify-center items-center h-full w-full'>
      <Row>
        <Col span={24} className='flex justify-center items-center'>
          <Spin size='large' />
        </Col>
        <Col span={24} className='flex justify-center items-center mt-1'>
          <h1 className='text-xl text-primary'>Cargando...</h1>
        </Col>
      </Row>
    </div>
  );
}
