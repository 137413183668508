import { Col, Row, Spin } from "antd";
import React, { useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { notification } from "antd";

import { SET_USER } from "../../store/actions";
import { useHistory, useLocation } from "react-router-dom";
// utils
import { logoutAndClearAll } from "layouts/utils";
import { GlobalContext } from "views/App";
import { autoLoginWhitelabelGql, meGql } from "./gqlTags";

const { REACT_APP_COMPANY_ID: company, REACT_APP_NATIONALITY: nationality } = process.env;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AutoLogin(props) {
  const { dispatch } = useContext(GlobalContext);
  const history = useHistory();
  const [autoLoginWhitelabel, { data, error, loading }] = useLazyQuery(autoLoginWhitelabelGql, {
    fetchPolicy: "no-cache",
  });
  let query = useQuery();
  const token = query.get("paciente");
  useEffect(() => {
    logoutAndClearAll();
    const variables = { token, nationality, company };
    autoLoginWhitelabel({ variables });
  }, [token, autoLoginWhitelabel]);

  const [getUserInfo, { loading: loadingMe, data: dataMe, error: errorMe }] = useLazyQuery(meGql, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!loadingMe) {
      if (errorMe) {
        dispatch({ type: SET_USER, payload: null });
        logoutAndClearAll();
        history.push("/login");
      }
      if (dataMe) {
        window.location = "/patient";
      }
    }
  }, [loadingMe, dataMe, errorMe, dispatch, history]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        localStorage.setItem("token", data.loginWhitelabel.token);
        localStorage.setItem("refreshToken", data.loginWhitelabel.refreshToken);
        getUserInfo();
      }
    }
  }, [loading, data, getUserInfo]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "No hay suficiente información",
        description: error?.message || error.graphQLErrors[0]?.message,
      });
    }
  }, [error]);

  if (error) {
    return (
      <div className='flex justify-center items-center' style={{ height: "100vh" }}>
        <Row>
          <Col span={24} className='flex justify-center items-center mt-1'>
            <h1 className='text-xl' style={{ color: "red" }}>
              {error?.message || error.graphQLErrors[0]?.message}
            </h1>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className='flex justify-center items-center' style={{ height: "100vh" }}>
      <Row>
        <Col span={24} className='flex justify-center items-center'>
          <Spin size='large' />
        </Col>
        <Col span={24} className='flex justify-center items-center mt-1'>
          <h1 className='text-xl text-primary'>Cargando...</h1>
        </Col>
      </Row>
    </div>
  );
}

export default AutoLogin;
