import React, { useContext } from "react";
import Button from "antd/lib/button";
import { faLaptopMedical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalContext } from "views/App";
import Link from "react-router-dom/Link";
import Lottie from "react-lottie";
import appoinmentSuccess from "../../../assets/lottie/appoinment-success.json";

const StepsFinal = () => {
  const { state: globalState } = useContext(GlobalContext);
  const { user } = globalState;

  const defaultOptionsAnimation = {
    loop: false,
    autoplay: true,
    animationData: appoinmentSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className='flex justify-center w-full mt-10'>
        <h3 className='text-xl text-primary text-center'>Su cita se ha agendado con éxito</h3>
      </div>
      <div className='flex justify-center w-full mt-1'>
        <div className='w-full lg:w-3/4 xl:w-2/4'>
          <Lottie isClickToPauseDisabled width='100%' options={defaultOptionsAnimation} />
        </div>
      </div>
      <div className='pb-6 flex justify-center'>
        <Button
          // onClick={() => setVisbleModal(true)}
          icon={<FontAwesomeIcon className='mx-2 text-center' size='sm' icon={faLaptopMedical} />}
          type='default'
          shape='round'
          size='large'
          className='button-success lg:-mt-1 block mt-4 lg:inline-block hover:text-white xs:mx-auto sm:ml-auto sm:mr-auto md:mx-4 md:ml-0'
        >
          <Link
            className='text-white'
            to={`/cita/doctormd/interconsulta/${user.appointmentIC?.id}`}
          >
            Ir a la sala de espera
          </Link>
        </Button>
      </div>
    </>
  );
};

export default StepsFinal;
