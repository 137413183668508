import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import { useForm } from "antd/es/form/Form";
// components
import Form from "./Form";
// context
import Context from "../Context";
// gql
import { muteAppointmentSymptoms } from "../../../../gql";
// helpers
import get from "lodash/get";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";

export default function Symptoms() {
  const [form] = useForm();
  const { newSymptoms, status, patient, id, editable } = useContext(Context);
  const [initialValues, setInitialValues] = useState({ newSymptoms });
  const [mute, { data }] = useMutation(muteAppointmentSymptoms, {
    variables: { id, status, patient: patient.id },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      const values = get(data, "newSymptoms", false);
      if (values) setInitialValues(values);
    }
  }, [data]);

  const onValuesChange = (_, variables) => {
    if (isEmpty(variables)) return;

    const isDiff = some(variables, (val, key) => {
      return initialValues[key] !== val;
    });

    if (isDiff) {
      mute({ variables })
        // .then(() => {stateQuery.refetch()})
        .catch((e) => console.log(e));
    }
  };

  const props = {
    Form: {
      form,
      initialValues,
      onValuesChange,
      disabled: !editable,
    },
  };

  return <Form {...props.Form} />;
}
