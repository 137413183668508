import Steps from "antd/lib/steps";
import React, { useContext } from "react";
import { wizardContext } from "../ModalWizard/ContendModal";

const StepsMD = (props) => {
  const { state } = useContext(wizardContext);
  const { Step } = Steps;

  if (state.stepsActive === null) {
    return null;
  } else {
    return (
      <Steps progressDot className={`${props.className}`} current={state.stepsActive}>
        <Step title={null} description={null} />
        <Step title={null} description={null} />
        <Step title={null} description={null} />
      </Steps>
    );
  }
};

StepsMD.defaultProps = {
  className: "",
};

export default StepsMD;
