import React, { useReducer, createContext, useContext } from "react";
import FooterModal from "./FooterModal";
import uniqueId from "lodash/uniqueId";

// antd
import Form from "antd/lib/form";
// componet
import Steps1 from "../Steps/Steps1";
import Steps2 from "../Steps/Steps2";
import Steps3 from "../Steps/Steps3";
import Steps4 from "../Steps/Steps4";
import StepsDefault from "../Steps/StepsDefault";
import StepsFinal from "../Steps/StepsFinal";

// store
import reducer from "../Store/reducerStore";
import initialState from "../Store/initialState";
import * as actionName from "../Store/actionStore";
import { GlobalContext } from "views/App";

// create context Store
export const wizardContext = createContext();

const ContendModal = (props) => {
  const { onClose } = props;
  const [stateLocal, dispatch] = useReducer(reducer, initialState);
  const { state: globalState, dispatch: globalDispatch } = useContext(GlobalContext);
  const { user } = globalState;
  const [form] = Form.useForm();

  const onFinishFormDetailsAppointments = (valuesForm) => {
    const { razónDeLaConsulta, sintomas, medicalconditions, drugAllergies } = valuesForm;
    dispatch({
      type: actionName.SET_MEDICALCONDITIONS,
      payload: medicalconditions,
    });
    dispatch({
      type: actionName.SET_DRUGALLERGIES,
      payload: drugAllergies,
    });
    dispatch({
      type: actionName.SET_SYMPTOMS,
      payload: sintomas,
    });
    dispatch({
      type: actionName.SET_REASON_APPOINTMENTS,
      payload: razónDeLaConsulta,
    });
    return dispatch({
      type: actionName.SET_STEPS,
      payload: stateLocal.stepsActive + 1,
    });
  };

  const RenderContendSteps = () => {
    const stepsActive = () => {
      switch (stateLocal.stepsActive) {
        case 0:
          return <Steps1 />;
        case 1:
          return (
            <Steps2
              key={uniqueId("Steps2_")}
              form={form}
              onFinishForm={onFinishFormDetailsAppointments}
            />
          );
        case 2:
          return <Steps3 />;
        case 3:
          return <Steps4 />;
        default:
          return <StepsDefault />;
      }
    };

    return stepsActive();
  };

  return (
    <wizardContext.Provider value={{ state: stateLocal, dispatch: dispatch }}>
      <h2 style={{ color: "#727272" }} className='text-2xl'>
        <strong>Inter-Consulta</strong>
      </h2>
      {stateLocal.finishWizard ? (
        <StepsFinal />
      ) : (
        <>
          <RenderContendSteps />
          <FooterModal user={user} globalDispatch={globalDispatch} onClose={onClose} form={form} />
        </>
      )}{" "}
    </wizardContext.Provider>
  );
};

export default ContendModal;
