import React from "react";
// antd
import Upload from "antd/lib/upload";
// icons
import { faPlus, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
// style
import style from "./style.module.scss";

const Preview = ({ file }) => (
  <div className='overflow-hidden relative'>
    <img alt='imagePerson' src={URL.createObjectURL(file)} />
    <Fa icon={faPen} className='text-white absolute right-0 bottom-0' />
  </div>
);

export default function UploadPicture({ value, onChange }) {
  const customRequest = ({ file, onSuccess }) => {
    onSuccess();
    if (onChange) onChange(file);
  };

  const props = {
    Wrapper: {
      className: style.cardUpload,
    },
    Upload: {
      customRequest,
      accept: "image/png,image/jpeg",
      listType: "picture-card",
      children: value ? <Preview file={value} /> : <Fa icon={faPlus} className='text-success' />,
      showUploadList: false,
    },
  };

  return (
    <div {...props.Wrapper}>
      <Upload {...props.Upload} />
      <span>Foto de Perfil</span>
    </div>
  );
}
