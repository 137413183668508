import React from "react";
import { Route, Switch } from "react-router-dom";
// views
import Patient from "views/Patient";
// hoc
import AuthWrapper from "./AuthWrapper";
// styles
import "./components.scss";

export default function Profile() {
  return (
    <AuthWrapper>
      <Switch>
        <Route path='/patient' render={() => <Patient />} />
      </Switch>
    </AuthWrapper>
  );
}
