import React, { useContext } from "react";
// antd
import Card from "antd/lib/card";
import Typography from "antd/lib/typography";
// components
import UpImg from "./UpImg";
import Avatar from "components/User/Avatar";
// context
import Context from "./Context";

const { Paragraph: P } = Typography;

export default function Meta() {
  const { name, label, selected, onSelect, gql, pic, id, isSimple, account } = useContext(Context);

  const onClick = () => (selected ? null : onSelect(id));

  const title = isSimple ? null : <span onClick={!account ? onClick : null}>{name}</span>;

  const props = {
    UpImg: {
      id,
      pic,
      gql: gql.upPic,
    },
    Texts: {
      onClick: !account ? onClick : null,
      ellipsis: true,
      children: label,
      className: "text-primary m-0",
    },
  };

  const avatar = !account && gql && gql.upPic ? <UpImg {...props.UpImg} /> : <Avatar src={pic} />;

  const description = isSimple ? null : <P {...props.Texts} />;

  const propsMeta = { avatar, title, description };

  return <Card.Meta {...propsMeta} />;
}
