import React, { forwardRef } from "react";
// antd
import Upload from "antd/lib/upload";

function InputFile(props, ref) {
  const { onChange, value, ...attr } = props;

  const customRequest = ({ file }) => {
    if (onChange) onChange(file);
  };

  const onRemove = () => {
    if (onChange) onChange(null);
  };

  const propsUpload = {
    ref,
    onRemove,
    customRequest,
    name: "file",
    accept: "image/x-png,image/jpeg",
    listType: "File",
    fileList: value ? [value] : [],
    ...attr,
  };

  return <Upload {...propsUpload} />;
}

export default forwardRef(InputFile);
