import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Form, Input, Button, Alert } from "antd";

import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalPassword from "../../components/ModalPasswordReset";
import { isArray } from "lodash";

import { findPhoneNumbersInText, isValidPhoneNumber } from "libphonenumber-js";

const FormLogin = (props) => {
  const [form] = Form.useForm();
  const [visbleModalReset, setVisbleModalReset] = useState(false);
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const getErrorMessage = () => {
    let message = "Correo electronico o contraseña incorrecta.";
    if (props.error.graphQLError) {
      if (isArray(props.error.graphQLErrors[0])) {
        message = props.error.graphQLErrors[0].message;
      }
    }
    return message;
  };

  const formatFinalData = (values) => {
    if (!props.loading) {
      const isValid = isValidPhoneNumber(values.email, "NI");
      const phoneNumber = isValid
        ? findPhoneNumbersInText(values.email, "NI")[0].number.nationalNumber
        : null;
      const newValues = {
        ...values,
        email: isValid ? phoneNumber.concat("@alianzaTigoEasyMD.com") : values.email,
        phoneNumber,
      };
      props.onFinishForm(newValues);
    }
  };

  const isPhoneNumberNic = (value) => {
    return isValidPhoneNumber(value, "NI");
  };

  return (
    <div>
      <ModalPassword
        visbleModalReset={visbleModalReset}
        setVisbleModalReset={setVisbleModalReset}
      />

      <h3 className='text-2xl mb-3'>
        <strong>{props.title}</strong>
      </h3>
      <p className='mb-3'>{props.description}</p>

      {props.alertError && props.error && (
        <Alert
          message='Error al iniciar sesión'
          description={getErrorMessage()}
          type='error'
          className='mb-1'
          showIcon
          closable
        />
      )}
      <Form
        form={form}
        layout='horizontal'
        validateTrigger='onBlur'
        name='basic'
        initialValues={{ remember: true }}
        onFinish={(valueform) => formatFinalData(valueform)}
        className='mb-8 mt-5'
      >
        <Form.Item
          label={null}
          name='email'
          rules={[
            {
              validator(_, value) {
                if (!value) {
                  return Promise.reject("!Campo requerido!");
                }
                const isValidPhone = isPhoneNumberNic(value);
                const isValidEmail = regexEmail.test(value);
                if (!isValidPhone && !isValidEmail) {
                  return Promise.reject("! Correo electrónico o número de teléfono incorrecto !");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            disabled={props.loading}
            size='large'
            prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />}
            className='rounded-t-lg'
            placeholder='Correo electrónico o número de teléfono'
          />
        </Form.Item>

        <Form.Item
          className='relative mb-0'
          style={{ top: "-1.563rem" }}
          name='password'
          rules={[
            {
              required: true,
              message: "! Por favor ingrese su contraseña !",
            },
          ]}
        >
          <Input.Password
            disabled={props.loading}
            size='large'
            autoComplete='new-password'
            prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />}
            className='rounded-b-lg'
            type='password'
            placeholder='Contraseña'
          />
        </Form.Item>

        <div className='flex justify-center md:justify-end w-full mb-3'>
          <Button
            type='link'
            shape='round'
            size='large'
            className='p-0'
            onClick={() => setVisbleModalReset(true)}
          >
            Olvide la contraseña
          </Button>
        </div>

        <div>
          <Button
            disabled={props.loading}
            loading={props.loading}
            htmlType='submit'
            type='primary'
            shape='round'
            size='large'
            className='my-5 mx-1 shadow-lg md:my-0'
          >
            Iniciar sesión
          </Button>
        </div>
      </Form>
    </div>
  );
};

FormLogin.defaultProps = {
  alertError: false,
  title: "Lorem ipsum",
  description:
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.",
  onFinishForm: {},
  loading: false,
  error: false,
  redirec: "/md/auth/signup",
};

export default React.memo(FormLogin);
