const initialState = {
  stepsActive: 0,
  appointmentsMethod: "CHAT",
  finishWizard: false,
  loadingGlobal: false,
  detailsAppointments: {
    reasonAppointments: "",
    DrugAllergies: [],
    MedicalConditions: [],
    symptoms: [],
  },
};

export default initialState;
