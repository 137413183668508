import style from "./style.module.scss";

export default {
  Modal: {
    width: 480,
    title: "Nuevo Beneficiario",
    okText: "FINALIZAR",
    centered: true,
    className: "Dialog",
    bodyStyle: {
      padding: 20,
      overflow: "hidden",
    },
    cancelButtonProps: {
      className: "btn-danger",
    },
    maskClosable: false,
    destroyOnClose: true,
  },
  Space: {
    size: 0,
    align: "start",
    className: style.Space,
  },
};
