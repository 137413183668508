import React from "react";
// antd
import Card from "antd/lib/card";
// components
import BtnActions from "./BtnActions";
// context
import Context from "./Context";

export default function ExamCard(props) {
  const { title } = props;
  const Title = (
    <p className='mb-0' style={{ marginTop: "-8px" }}>
      {title}
    </p>
  );

  const propsCard = {
    size: "small",
    title: Title,
    extra: <BtnActions />,
    className: "pl-2 mb-3",
    bodyStyle: {
      display: "none",
    },
  };

  return (
    <Context.Provider value={props}>
      <Card {...propsCard} />
    </Context.Provider>
  );
}
