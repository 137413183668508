import React, { Fragment, useContext, useState } from "react";
// antd
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
// Components
import Preview from "components/ModalPreviewFile/HOC";
// icons
import { faEye, faTrashAlt, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
// context
import Context from "./Context";

export default function BtnActions() {
  const { id, title: name, media, onRemove, editable } = useContext(Context);
  const [removing, setRemoving] = useState(false);
  const { type, url } = media;

  const onClick = () => {
    Modal.confirm({
      onOk: () => {
        onRemove(id);
        setRemoving(true);
      },
      title: `Remover ${name}`,
      okText: "Si",
      cancelText: "No",
    });
  };

  const props = {
    BtnDisplay: {
      type: "link",
      icon: <FA icon={faEye} />,
      shape: "circle",
      disabled: removing,
    },
    BtnRemove: {
      onClick,
      type: "link",
      icon: <FA icon={faTrashAlt} />,
      shape: "circle",
      danger: true,
      loading: removing,
      disabled: removing,
    },
    BtnDownload: {
      type: "link",
      href: url,
      icon: <FA icon={faDownload} />,
      shape: "circle",
      download: true,
      disabled: removing,
    },
    Preview: {
      file: { url, type, name },
    },
  };

  return (
    <Fragment>
      {type === "application/pdf" ? (
        <Button {...props.BtnDownload} />
      ) : (
        <Preview {...props.Preview}>
          <Button {...props.BtnDisplay} />
        </Preview>
      )}
      {editable && <Button {...props.BtnRemove} />}
    </Fragment>
  );
}
