import React, { useContext } from "react";
import moment from "moment";
import QueueAnim from "rc-queue-anim";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
// antd
import Spin from "antd/lib/spin";
import Card from "antd/lib/card";
import Button from "antd/lib/button";
import Typography from "antd/lib/typography";
// components
import AppointmentCard from "components/Person/Card/Appointment";
import WithOutAppointment from "../../WithOutAppointment";
// gql
import { getMeAppointments } from "../../gql";
// context
import { GlobalContext } from "views/App";
import Context from "views/Patient/Context";
// helpers
import get from "lodash/get";
import map from "lodash/map";
import join from "lodash/join";
// style
import style from "../../style.module.scss";
// img default
import defaultPic from "assets/img/doctorDefaulImg.png";

const { Title } = Typography;

const propsDefault = {
  title: "Mis Citas Agendadas",
  children: <WithOutAppointment />,
  className: style.Appointments,
};

export default function Appointments() {
  const history = useHistory();
  const { state } = useContext(GlobalContext);
  const { setAppointment } = useContext(Context);
  const { data, loading } = useQuery(getMeAppointments, {
    variables: { id: state.user.id },
    fetchPolicy: "no-cache",
  });

  const list = get(data, "appointments.docs", []);

  if (!loading && !list.length) return <Card {...propsDefault} />;

  const goAppointment = (id) => {
    window.location = `/cita/paciente/consultainmediata/${id}`;
  };

  const appointments = list.map((appt) => {
    const { id, type, medic, date, description, appointmentSpecialty, status } = appt;
    const isMD = type.includes("MD");
    const props = {
      id,
      description,
      key: id,
      pic: isMD ? defaultPic : medic?.pic.url,
      name: isMD ? "Cita Inmediata" : medic?.name,
      time: moment(date).format("LLLL"),
      label: isMD ? appointmentSpecialty : join(map(medic?.specialties, "name"), ", "),
      onSelect: isMD && status !== "COMPLETE" ? goAppointment : setAppointment,
      className: isMD ? "MD" : "",
    };

    return <AppointmentCard {...props} />;
  });

  const props = {
    Title: {
      children: loading ? <Spin /> : list.length,
      className: "text-primary m-0",
    },
    LinkSeeMore: {
      type: "link",
      onClick: () => setAppointment("all"),
      disabled: loading,
      children: "Ver más",
      className: "float-right py-0",
    },
    Queue: {
      key: "tabs",
      type: "bottom",
      children: appointments,
      className: style.list,
    },
  };

  const propsCard = {
    ...propsDefault,
    loading,
    cover: <Title {...props.Title} />,
    actions: [<Button {...props.LinkSeeMore} />],
    children: <QueueAnim {...props.Queue} />,
  };

  return <Card {...propsCard} />;
}
