import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import error404 from "../../assets/lottie/error404.json";

const View404Router = ({ homeButton = true, text = "Página no encontrada", width = "35%" }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: error404,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className='flex h-auto mb-5 xs:mt-20 md:mt-32'>
      <div className='flex flex-col w-full my-5'>
        <p className='xs:text-lg md:text-2xl mx-auto text-warning font-bold my-3'>{text}</p>
        {homeButton && (
          <Button type='default' shape='round' size='large' danger className='mx-auto'>
            <Link to='/md/home' className='text-danger hover:text-danger-hover break-words'>
              {" "}
              Ir al Inicio{" "}
            </Link>
          </Button>
        )}
        <Lottie isClickToPauseDisabled options={defaultOptions} height='70%' width={width} />
      </div>
    </div>
  );
};

export default View404Router;
