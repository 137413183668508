import React from "react";
// ant

import { Input, Form, Tooltip, Select } from "antd";

const InputForm = (_) => {
  const { typeInput, seeTooltip } = _;
  const { TextArea, Search, Group, Password } = Input;
  const { Option } = Select;
  const props = {
    item: { ..._.item },
    input: { ..._.input },
    select: { ..._.select },
    options: _.options || [],
    tooltip: {
      placement: "topRight",
      title: (
        <div>
          <p className='pl-2'>Requisitos mínimos de la contraseña </p>
          <ul className='text-left pl-3 pt-0'>
            <li>Ocho caracteres mínimo</li>
            <li>Al menos un Número</li>
          </ul>
        </div>
      ),
    },
  };

  switch (typeInput) {
    case "text":
      return (
        <Form.Item {...props.item}>
          <Input {...props.input} />
        </Form.Item>
      );

    case "textArea":
      return (
        <Form.Item {...props.item}>
          <TextArea {...props.input} />
        </Form.Item>
      );

    case "search":
      return (
        <Form.Item {...props.item}>
          <Search {...props.input} />
        </Form.Item>
      );

    case "group":
      return (
        <Form.Item {...props.item}>
          <Group {...props.input} />
        </Form.Item>
      );

    case "password": {
      if (seeTooltip) {
        return (
          <Tooltip {...props.tooltip}>
            <Form.Item {...props.item}>
              <Password {...props.input} />
            </Form.Item>
          </Tooltip>
        );
      } else {
        return (
          <Form.Item {...props.item}>
            <Password {...props.input} />
          </Form.Item>
        );
      }
    }

    case "select":
      return (
        <Form.Item {...props.item}>
          <Select {...props.select}>
            {props.options.map((option, i) => (
              <Option key={i} value={option}>
                <span className='capitalize'>{option}</span>
              </Option>
            ))}
          </Select>
        </Form.Item>
      );

    default:
      return (
        <Form.Item {...props.item}>
          <Input {...props.input} />
        </Form.Item>
      );
  }
};

InputForm.defaultProps = {
  typeInput: "text",
  seeTooltip: true,
  item: {
    name: "name",
    rules: [],
  },
  input: {
    size: "large",
    className: "",
    placeholder: "Ingresar name",
    disabled: false,
  },
};

export default InputForm;
