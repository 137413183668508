import PacienteConsultaInmediata from "../views/videoCall/pacienteMD";

const routes = [
  {
    path: "/paciente/consultainmediata/:id",
    name: "Video Llamada Paciente",
    icon: "",
    component: PacienteConsultaInmediata,
    layout: "/cita",
  },
];

export default routes;
