import React from "react";
import moment from "moment";

export default function Bottom() {
  return (
    <div className='text-center font-semibold text-white mt-4 flex flex-col md:flex-row border-t justify-between border-success pt-4 '>
      <p>
        Powered by <b>EasyMD</b> LLC.
      </p>

      <p className='m-0'>
        {/* <HoccModal>
          <span className='cursor-pointer'>Terminos y condiciones</span>
        </HoccModal> */}
        {/* <span className='mx-4'>|</span> */}
        {/* <span onClick={() => history.push("/md/privacyPolicy")}>Política de privacidad</span> */}
      </p>
    </div>
  );
}
