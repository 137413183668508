import React from "react";
// andt
import List from "antd/lib/list";
import Avatar from "antd/lib/avatar";
import imagDefault from "../../assets/img/userPatient.png";

const RowAvatar = (_) => {
  const { row, text, description } = _;

  const props = {
    itemLayout: "horizontal",
    dataSource: [row],
    renderItem: (item) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src={item.pictureURL ? item.pictureURL : imagDefault} />}
          title={<p className='text-left pb-0 mb-0'>{text}</p>}
          description={description}
        />
      </List.Item>
    ),
  };
  return <List {...props} />;
};

RowAvatar.defaultProps = {
  text: "Lorem ipsum dolor sit amet",
  row: {},
  description: null,
};

export default RowAvatar;
