import React from "react";
// import PropsType from 'pr'
import classes from "classnames/bind";
// antd
import Card from "antd/lib/card";
// components
import Meta from "./Meta";
import Extra from "./Extra";
// context
import Context from "./Context";
// styles
import style from "./style.module.scss";

const cx = classes.bind(style);

export default function PersonCard(props) {
  const { selected, className, isSimple } = props;
  const propsCard = {
    title: <Meta />,
    extra: isSimple ? null : <Extra />,
    bordered: false,
    className: isSimple ? style.cardSimple : cx("card", { selected }, className),
    bodyStyle: { display: "none" },
  };

  return (
    <Context.Provider value={{ ...props }}>
      <Card {...propsCard} />
    </Context.Provider>
  );
}
