import gql from "graphql-tag";

export const getMedicalConditions = gql`
  query getMedicalconditions($page: Float, $perPage: Float, $filters: [medicalconditionFilter!]) {
    medicalconditions(page: $page, perPage: $perPage, filters: $filters) {
      docs {
        value: id
        label: name
      }
    }
  }
`;

export const getDrugs = gql`
  query getDrugs($perPage: Float, $page: Float, $filters: [drugFilter!]) {
    drugs(perPage: $perPage, page: $page, filters: $filters) {
      docs {
        value: id
        label: name
      }
    }
  }
`;
