import React, { useContext } from "react";
// components
import User from "components/User";
import Person from "components/Person";
// context
import Context from "./Context";
// gql
import { upMePic as upPic } from "./gql";
// icons
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { GlobalContext } from "views/App";
// helpers
import get from "lodash/get";
import { Col, Row } from "antd";

const Title = ({ className, ...attr }) => <p className={`title ${className}`} {...attr} />;

export default function Sider() {
  const { state } = useContext(GlobalContext);
  const { person, setPerson } = useContext(Context);
  const { pictureURL, fullName, id } = state.user;

  const props = {
    Person: {
      id,
      gql: { upPic },
      pic: get(pictureURL, "url"),
      size: 40,
      name: fullName,
      label: "Titular",
      onSelect: setPerson,
      selected: person === id,
    },
  };

  return (
    <>
      <Row gutter={[16, 16]} justify='end' align='middle'>
        <Col flex={1}>
          <Person.Card {...props.Person} />
        </Col>
        <Col>
          <User>
            <Title className='cursor-pointer'>
              <span className='mr-3'>Mi perfil</span>
              <FA icon={faChevronDown} className='text-base' />
            </Title>
          </User>
        </Col>
      </Row>
    </>
  );
}
