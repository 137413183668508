import React from "react";
import Result from "antd/lib/result";

const StepsDefault = () => {
  return (
    <Result
      status='error'
      title='Error al realizar su consulta '
      subTitle='A ocurrido un error al solicitar la consulta, por favor inténtelo más tarde.'
    />
  );
};

export default StepsDefault;
