import React, { useContext, Fragment } from "react";
import moment from "moment";
// antd
import Typography from "antd/lib/typography";
// components
import Title from "./Title";
import Symptoms from "./Symptoms";
import BtnAction from "./BtnAction";
// context
import Context from "./Context";
// icons
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

const { Paragraph: P } = Typography;

export default function Trace() {
  const { date, status } = useContext(Context);
  const time = moment(date).format("LLLL");

  const props = {
    Time: {
      title: time,
      ellipsis: true,
      children: (
        <Fragment>
          <FA icon={faClock} />
          <span className='ml-1'>{time}</span>
        </Fragment>
      ),
      className: "text-primary",
    },
    Title: {
      children: "Lista de síntomas",
    },
    Title2: {
      children: "Fecha y Hora:",
      className: "text-primary m-0",
    },
  };

  return (
    <Fragment>
      <Title {...props.Title} />
      <Symptoms />
      <Title {...props.Title2} />
      <P {...props.Time} />
      {status !== "CANCELED" && <BtnAction />}
    </Fragment>
  );
}
