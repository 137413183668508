import React from "react";
import { Layout } from "antd";
import { Route, Switch, Redirect } from "react-router-dom";
import { videoCallRoutes } from "../../routes";
import View404Router from "../../components/View404Router";
import VerifyLoginWrapper from "../../components/VerifyLoginWrapper";
import Brand from "./brand";

const { Content } = Layout;

const LayoutVideoCall = () => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return <Route path={prop.layout + prop.path} component={prop.component} key={key} exact />;
    });
  };

  return (
    <Layout className='layout h-auto p-0 bg-white'>
      <Content className='p-0 w-full min-h-full bg-white'>
        <div className='site-layout-content relative'>
          <VerifyLoginWrapper forceSignUp={false} loadingNode={<Brand />}>
            <Switch>
              {getRoutes(videoCallRoutes)}
              <Route component={() => <View404Router />} />
              <Route exact path='/cita'>
                <Redirect to='/md/home' />
              </Route>
            </Switch>
          </VerifyLoginWrapper>
        </div>
      </Content>
    </Layout>
  );
};

export default LayoutVideoCall;
