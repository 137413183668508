import jwt from "jsonwebtoken";
import { Endcrypt } from "endcrypt";
const newEndcrypt = new Endcrypt();

export const getNiceString = (name) => {
  const miNewDate = new Date();
  const tokenPlain = jwt.sign(
    {
      a: miNewDate.toUTCString(),
      b: miNewDate.toTimeString(),
      c: miNewDate.toDateString(),
      d: miNewDate.toISOString(),
      e: miNewDate.toLocaleTimeString(),
    },
    name + "_" + name + "_" + name.length,
    { expiresIn: "20s" }
  );
  return newEndcrypt.encryptWithKey(
    JSON.stringify(tokenPlain),
    name + "_" + name + "_" + name + "_" + name.length
  );
};
