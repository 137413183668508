import gql from "graphql-tag";

export const me = gql`
  query ME {
    me {
      id
      firstName
      lastName
      fullName
      verified
      email
      sex
      country
      openFireUsername
      openFirePassword
      beneficiaryOf
      belongsToCompany {
        id
        companyPlan {
          id
        }
      }
      phoneNumber
      appointmentMD {
        id
        reasonForConsultation
      }
      appointmentIC {
        id
        reasonForConsultation
      }
      permissions {
        id
        name
      }
      pictureURL {
        url
      }
      role {
        id
        name
      }
      plans {
        plan {
          id
          name
          beneficiaries
          features {
            name
            disabled
          }
        }
      }
    }
  }
`;

export const addCalendarGql = gql`
  mutation addCalendar($doctor: String!, $code: String!) {
    createCalendar(doctor: $doctor, code: $code) {
      id
    }
  }
`;
