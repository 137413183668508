import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
// antd
import local from "antd/es/locale/es_ES";
import ConfigProvider from "antd/lib/config-provider";
// components
import Tabs from "./Tabs";
import Sider from "./Sider";
import Layout from "layouts/profile/Layout";
// context
import Context from "./Context";
import { GlobalContext } from "views/App";
// helpers
import get from "lodash/get";

export default function PatientView() {
  const history = useHistory();
  const { state } = useContext(GlobalContext);
  const { id } = state.user;
  const [person, setPerson] = useState(id);
  const [appointment, setAppointment] = useState(null);

  const rol = get(state, "user.role.name", null);

  if (rol !== "patient") {
    history.push({ pathname: "/" });
    return null;
  }

  const propsLayout = {
    sider: <Sider />,
    content: <Tabs />,
  };

  const valContext = {
    person,
    setPerson,
    appointment,
    setAppointment,
  };

  return (
    <ConfigProvider locale={local}>
      <Context.Provider value={valContext}>
        <Layout {...propsLayout} />
      </Context.Provider>
    </ConfigProvider>
  );
}
