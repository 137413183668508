const initialState = {
  stepsActive: 0,
  specialtyActive: "GENERAL",
  appointmentsMethod: "CHAT",
  finishWizard: false,
  loadingGlobal: true,
  detailsAppointments: {
    reasonAppointments: "",
    DrugAllergies: [],
    MedicalConditions: [],
    symptoms: [],
  },
};

export default initialState;
