import gql from "graphql-tag";

export const appointmentMDGql = gql`
  query appointmentmdById($id: String!) {
    appointmentmdById(id: $id) {
      log {
        firstMessagePatient
        firstMessageDoctor
      }
      id
      status
      reasonForConsultation
      newSymptoms
      appointmentType
      vonageSessionId
      patient {
        id
        openFireUsername
        openFirePassword
        firstName
        lastName
        fullName
        pictureURL {
          url
        }
      }
      doctor {
        id
        openFireUsername
        fullName
        pictureURL {
          url
        }
      }
    }
  }
`;

export const appointmentStatusGql = gql`
  query appointmentmdById($id: String!) {
    appointmentmdById(id: $id) {
      status
      doctor {
        id
        fullName
        pictureURL {
          url
        }
        openFireUsername
      }
    }
  }
`;
