import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";

// gql
import { me } from "./gql";
// actions
import { SET_USER } from "store/actions";
// context
import { GlobalContext } from "views/App";
import { Row, Col, Spin } from "antd";

const Loading = () => (
  <div className='w-screen h-screen flex justify-center items-center'>
    <Row>
      <Col span={24} className='flex justify-center items-center'>
        <Spin size='large' />
      </Col>
      <Col span={24} className='flex justify-center items-center mt-1'>
        <h1 className='text-xl text-primary'>Cargando...</h1>
      </Col>
    </Row>
  </div>
);

export default function AuthWrapper({ children }) {
  const { state, dispatch } = useContext(GlobalContext);
  const [getUser, { data, loading, error }] = useLazyQuery(me);
  const history = useHistory();

  useEffect(() => {
    if (!state.user) getUser();
  }, [state.user, getUser]);

  useEffect(() => {
    if (error && !loading && !data) {
      history.push("/login");
    }
    if (data && !error) {
      dispatch({ type: SET_USER, payload: data.me });
    }
  }, [data, error, dispatch, history, loading]);

  return state.user ? children : <Loading />;
}
