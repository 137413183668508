import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import Upload from "antd/lib/upload";
import Button from "antd/lib/button";
import Notify from "antd/lib/notification";
// components
import Avatar from "components/User/Avatar";
// static props
import props from "./staticProps";
// helpers
import get from "lodash/get";
import keys from "lodash/keys";
import { isMaximumSize } from "./utils";

export default function UpImg({ pic, gql, id }) {
  const [img, setImg] = useState(pic);
  const [upFile, { loading, data, error }] = useMutation(gql);

  useEffect(() => {
    if (!error && data) {
      const [path] = keys(data);
      const url = get(data, [path, "pictureURL", "url"], false);
      if (url) setImg(url);
    }
  }, [data, error]);

  const customRequest = (params) => {
    const { file = {}, onSuccess, onError } = params;
    const er = /(jpg|jpeg|bmp|gif|png)$/i;

    if (!er.exec(file.type)) {
      Notify.error({ message: "El archivo no está permitido" });
      return onError();
    }

    if (isMaximumSize(file.size)) {
      Notify.error({
        message: "El archivo excede el tamaño máximo de carga",
        description: "5MB Máximo",
      });
      return onError();
    }

    const variables = { id, file };
    try {
      upFile({ variables })
        .then(onSuccess)
        .catch((e) => {
          onError(e);
          Notify.error({
            message: "Error al intentar subir el archivo",
            description: e.message,
          });
        });
    } catch (e) {
      console.log(e);
      onError(e);
    }
  };

  const propsUpload = {
    ...props.Upload,
    customRequest,
    disabled: loading,
    children: <Button {...props.Btn} disabled={loading} loading={loading} />,
  };

  return (
    <div className='relative'>
      <Avatar src={img} />
      <Upload {...propsUpload} />
    </div>
  );
}
