import React, { useState, useEffect, forwardRef } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
// components
import Selector from "./Selector";
// helper
import get from "lodash/get";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";

function InputWordCloud(props, ref) {
  const { gql, path, lazyQuery = false, value, onChange, ...attr } = props;

  const [values, setValues] = useState(value || []);
  const [variables, setVariables] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const [fetch, { loading, data }] = useLazyQuery(gql, { variables });
  const options = lazyQuery && isEmpty(searchValue) ? [] : get(data, path, []);

  useEffect(() => {
    if (!lazyQuery) fetch();
  }, []);

  useEffect(() => {
    if (onChange) onChange(values);
  }, [values]);

  useEffect(() => {
    if (
      (value && value.length !== values.length) ||
      some(value, (val, key) => values[key] !== val)
    ) {
      setValues(value);
    }
  }, [value]);

  const onSearch = (value = "") => {
    if (isEmpty(value)) setVariables({});
    else {
      const filters = [{ byText: { field: "NAME", value } }];
      setVariables({ filters, page: 1, perPage: 25 });
    }
    setSearchValue(value);
  };

  const onSelect = (_, { label }) => setValues([label, ...values]);

  const onRemove = (val) => {
    const index = values.indexOf(val);
    const newValues = [...values.slice(0, index), ...values.slice(index + 1)];
    setValues(newValues);
  };

  const onInputKeyDown = ({ keyCode }) => {
    if (keyCode === 13 && !isEmpty(searchValue)) {
      setValues([searchValue, ...values]);
    }
  };

  const notFoundContent = (
    <span>
      Presionar enter para agregar: <b>{searchValue}</b>
    </span>
  );

  const propsSelect = {
    ref,
    values,
    loading,
    options,
    onSearch,
    onSelect,
    onRemove,
    onInputKeyDown,
    notFoundContent,
    ...attr,
  };

  return <Selector {...propsSelect} />;
}

export default forwardRef(InputWordCloud);
