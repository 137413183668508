import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ErrorCita from "../../../components/CitasUtils/ErrorCita";

const CitaNoEncontrada = ({ url }) => {
  const urlPerfil = url || "/patient";
  return (
    <div className='flex h-auto'>
      <div className='flex flex-col w-full my-5'>
        <p className='xs:text-lg md:text-2xl mx-auto text-danger font-bold my-3'>
          CITA MÉDICA NO ENCONTRADA
        </p>
        <div className='flex-1 mx-auto'>
          <Link to={urlPerfil} className='text-danger hover:text-danger-hover break-words'>
            <Button type='default' shape='round' size='large' danger className='mx-auto  w-full'>
              <FontAwesomeIcon icon={faUser} className='mr-2 hover:text-danger-hover' />
              Ir a mi perfil
            </Button>
          </Link>
        </div>
        <ErrorCita />
      </div>
    </div>
  );
};

export default CitaNoEncontrada;
