import { InMemoryCache } from "apollo-boost";
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { ApolloLink, Observable } from "apollo-link";
import { onError } from "apollo-link-error";
import jwtDecode from "jwt-decode";

import { getNiceString } from "./utils";
import { URI } from "../utils.js/constants";

const httpLink = createUploadLink({
  uri: URI,
});

const WHITE_LINKS = ["login", "signup"];

const refreshToken = async () => {
  // Get the token from LS
  const refreshToken = localStorage.getItem("refreshToken");
  const data = JSON.stringify({
    query: `{  refreshToken(refreshToken: "${
      refreshToken || "invalid"
    }"){  token, refreshToken } }`,
  });

  const token = await fetch(URI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  }).then((response) => response.json());

  if (token.errors) {
    throw new Error("TokenExpiredError: jwt expired");
  }

  const newToken = token.data.refreshToken.token;
  const newRefreshToken = token.data.refreshToken.refreshToken;
  localStorage.setItem("token", newToken);
  localStorage.setItem("refreshToken", newRefreshToken);

  return newToken;
};

const errorHandler = onError(({ graphQLErrors, operation, forward, response }) => {
  let { operationName } = operation;
  if (operationName) {
    operationName = operationName.toLowerCase();
  }
  if (WHITE_LINKS.indexOf(operationName) > -1) {
    return forward(operation);
  } else if (graphQLErrors) {
    const errorMessage = graphQLErrors[0].extensions.code;
    // UNAUTHENTICATED
    if (errorMessage === "UNAUTHENTICATED") {
      // Let's refresh token through async request
      return new Observable((observer) => {
        refreshToken()
          .then((refreshResponse) => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: `Bearer ${refreshResponse}` || null,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            // No refresh or client token available, we force user to login
            observer.error(error);
          });
      });
    }
  }
});

const authLink = setContext(async (request, { headers }) => {
  const token = localStorage.getItem("token");
  const niceString = request.operationName ? getNiceString(request.operationName) : null;
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = Math.round(Date.now() / 1000);
    if (decoded.exp <= currentTime) {
      const newToken = await refreshToken();
      if (newToken) {
        return {
          headers: {
            ...headers,
            authorization: newToken ? `Bearer ${newToken}` : "",
            nonce: niceString ? niceString.e.toString() : new Date().toLocaleString(),
            boli: niceString ? niceString.iv.toString() : new Date().toLocaleString(),
          },
        };
      }
    }
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      nonce: niceString ? niceString.e.toString() : new Date().toLocaleString(),
      boli: niceString ? niceString.iv.toString() : new Date().toLocaleString(),
    },
  };
});

const link = ApolloLink.from([errorHandler, authLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
