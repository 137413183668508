import React, { useContext, useEffect, useState, useCallback } from "react";
import { Layout, Menu, Spin } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// logo
// import Logo from "assets/img/logoEasyMD.png";
import Logo from "assets/img/bioanalisisLogo.png";

// components
import User from "components/User";

// context
import { useLazyQuery } from "@apollo/react-hooks";
import MeGql from "./gql";
import { SET_USER } from "store/actions";
import { GlobalContext } from "views/App";

import Headroom from "react-headroom";

import get from "lodash/get";
import lowerCase from "lodash/lowerCase";

// utils
import { logoutAndClearAll } from "layouts/utils";

// styles
import styles from "./styles.module.scss";
import "./antdOverrideHeader.scss";

const { Header } = Layout;

export const ROUTES_HOME = {
  home: {
    url: "/md/home",
    key: "0",
  },
  familiar: {
    url: "/md/familiar",
    key: "1",
  },
  empresa: {
    url: "/md/empresa",
    key: "2",
  },
  // tecnologia: {
  //   url: "/md/tecnologia",
  //   key: "3",
  // },
  especialistas: {
    url: "/md/encuentra/especialistas",
    key: "4",
  },
  loading: {
    url: null,
    key: "5",
  },
  userLogo: {
    url: null,
    key: "6",
  },
  login: {
    url: "/md/auth/login",
    key: "7",
  },
  signup: {
    url: "/md/auth/signup",
    key: "8",
  },
  mobileApp: {
    url: "https://play.google.com/store/apps/details?id=com.easymdv2.android",
    key: "9",
  },
};

const HeaderHome = () => {
  const location = useLocation();
  const [currentPathName, setCurrentPathName] = useState(get(location, "pathname", null));
  const token = localStorage.getItem("refreshToken");

  // store
  const { state, dispatch } = useContext(GlobalContext);

  const { user } = state;

  const { listen } = useHistory();

  // Queries
  const [sendMeUser, { data, error, loading }] = useLazyQuery(MeGql, { fetchPolicy: "no-cache" });

  useEffect(() => {
    listen((newLocation) => {
      setCurrentPathName(get(newLocation, "pathname", null));
    });
  }, [listen]);

  const getUser = useCallback(() => {
    sendMeUser();
  }, [sendMeUser]);

  useEffect(() => {
    !user && token && getUser();
  }, [user, token, getUser]);

  useEffect(() => {
    if (!loading) {
      if (data && data.me) {
        dispatch({ type: SET_USER, payload: data.me });
      } else if (error) {
        logoutAndClearAll();
        dispatch({ type: SET_USER, payload: null });
      }
    }
  }, [data, loading, error, dispatch]);

  const getDefaultSelect = () => {
    const current = [];
    for (const [value] of Object.entries(ROUTES_HOME)) {
      if (lowerCase(value.url) === lowerCase(currentPathName)) {
        current.push(value.key);
      }
    }
    return current;
  };

  return (
    <Headroom
      style={{
        height: "auto",
        zIndex: "2",
        width: "100%",
      }}
      className='HeadroomMD'
    >
      <Header className='shadow-md bg-white px-4 md:p-0'>
        <div className='container mx-auto'>
          <div className={`${styles.containerLogo}  flex xs:pr-3 md:pr-5`}>
            <Link to={ROUTES_HOME.home.url} className='my-auto mx-auto'>
              <img alt='easyMDLogo' src={Logo} style={{ objectFit: "contain" }} />
            </Link>
          </div>

          <Menu
            className='headerEasyMD bg-white flex lg:ml-5'
            mode='horizontal'
            overflowedIndicator={
              <FontAwesomeIcon
                className='text-black hover:text-primary focus:text-primary'
                size='2x'
                icon={faBars}
              />
            }
            selectedKeys={getDefaultSelect()}
          >
            {loading && (
              <Menu.Item
                key={ROUTES_HOME.loading.key}
                className='ml-auto bg-transparent lg:justify-self-end flex p-0'
              >
                <div className='my-auto mx-auto'>
                  <Spin />
                </div>
              </Menu.Item>
            )}

            {!loading && user !== null && (
              <Menu.Item
                key={ROUTES_HOME.userLogo.key}
                className='ml-auto bg-transparent justify-self-end flex p-0'
              >
                <div className=' my-auto text-black mx-auto'>
                  <User size={40} />
                </div>
              </Menu.Item>
            )}
          </Menu>
        </div>
      </Header>
    </Headroom>
  );
};

export default HeaderHome;
