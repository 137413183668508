import gql from "graphql-tag";
export const autoLoginWhitelabelGql = gql`
  query loginWhitelabel($token: String!, $nationality: NationalityEnum, $company: String!) {
    loginWhitelabel(token: $token, nationality: $nationality, company: $company) {
      token
      refreshToken
    }
  }
`;
export const meGql = gql`
  query {
    me {
      email
      role {
        name
      }
      verified
      fullName
      pictureURL {
        url
      }
      sex
      country
      id
      phoneNumber
      belongsToCompany {
        id
      }
      appointmentMD {
        id
        reasonForConsultation
      }
    }
  }
`;
