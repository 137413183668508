import React from "react";

// ant
import Form from "antd/lib/form";
import Checkbox from "antd/lib/checkbox";
import Button from "antd/lib/button";
// componets
import HoccModal from "components/ModalTermsAndConditions/HoccModal";

const InputCheck = (_) => {
  const props = {
    item: { ..._.item },
    checkbox: { ..._.checkbox },
    button: { ..._.button },
    setFields: _.setFields,
  };
  return (
    <Form.Item {...props.item}>
      <Checkbox {...props.checkbox}>
        <span>Acepto los</span>
        <HoccModal onSucces={() => props.setFields()}>
          <Button {...props.button}>terminos y condiciones</Button>
        </HoccModal>
      </Checkbox>
    </Form.Item>
  );
};

InputCheck.defaultProps = {
  item: {
    className: "",
    name: "InputCheck",
    valuePropName: "checked",
    rules: [],
  },
  setFields: () => {},
  checkbox: { disabled: false },
  button: {
    className: "",
    disabled: false,
    loading: false,
    type: "link",
    onClick: () => {},
  },
};

export default InputCheck;
