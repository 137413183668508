import React, { Fragment } from "react";
// import moment from 'moment'
import classes from "classnames/bind";
// antd
import Card from "antd/lib/card";
import Typography from "antd/lib/typography";
// components
import Avatar from "components/User/Avatar";
// icons
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faChevronRight, faClock } from "@fortawesome/free-solid-svg-icons";
// styles
import style from "./style.module.scss";

const cx = classes.bind(style);

const { Paragraph: P } = Typography;

export default function Appointment(_props) {
  const { name, label, pic, description, onSelect, id, className, time, ...atts } = _props;

  const props = {
    Avatar: {
      src: pic,
      size: 70,
      shape: "square",
    },
    Description: {
      style: { lineHeight: 1 },
      ellipsis: { rows: 3 },
      children: description,
    },
    Time: {
      title: time,
      ellipsis: true,
      children: (
        <Fragment>
          <FA icon={faClock} />
          <span className='ml-1'>{time}</span>
        </Fragment>
      ),
      className: "text-primary m-0",
    },
  };

  const propsMeta = {
    title: name,
    avatar: <Avatar {...props.Avatar} />,
    description: (
      <Fragment>
        <p>{label}</p>
        {description && (
          <div className='mt-1 mb-3 whitespace-normal'>
            <P {...props.Description} />
          </div>
        )}
        {time && <P {...props.Time} />}
      </Fragment>
    ),
  };

  const propsCard = {
    title: <Card.Meta {...propsMeta} />,
    extra: <FA icon={faChevronRight} />,
    onClick: () => (onSelect ? onSelect(id) : null),
    bordered: false,
    className: cx("card", "appointment", className),
    bodyStyle: { display: "none" },
    ...atts,
  };

  return <Card {...propsCard} />;
}
