import React from "react";
import { Row, Col, Empty, Tag } from "antd";

const InfoCita = (props) => {
  const { infoAppointment } = props;

  return (
    <div className='xs:w-2/3 lg:1/4 mx-auto'>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={12} lg={16} className='px-3'>
          <p className='break-words font-bold text-lg text-success mb-2'>Razón de la cita:</p>
          <p className='break-words font-semibold text-lg text-primary mb-2'>
            {infoAppointment.reasonForConsultation}
          </p>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <p className='break-words font-bold text-lg text-success mb-2'>Síntomas:</p>
          {infoAppointment.newSymptoms.length < 1 ? (
            <Empty description={false} />
          ) : (
            infoAppointment.newSymptoms.map((value, index) => {
              return (
                <Tag
                  key={index}
                  style={{ backgroundColor: "#ECF1FF" }}
                  title={value}
                  className='my-1 text-black-hover rounded-full text-base h-auto py-1 hover:text-primary truncate'
                >
                  <strong className='text-xs'>{value}</strong>
                </Tag>
              );
            })
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InfoCita;
