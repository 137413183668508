import React, { useReducer, createContext, useContext, useEffect } from "react";
import FooterModal from "./FooterModal";
import uniqueId from "lodash/uniqueId";
import { useQuery } from "@apollo/react-hooks";
import { patientByIdWizardMD } from "../gqlWizard";

// util
import { HandleError } from "utils.js/HandleError";
// antd
import Form from "antd/lib/form";
import Spin from "antd/lib/spin";
import notification from "antd/lib/notification";
// componet
import StepsMD from "../Steps";
import Steps1 from "../Steps/Steps1";
import Steps2 from "../Steps/Steps2";
import Steps3 from "../Steps/Steps3";
import StepsDefault from "../Steps/StepsDefault";
import StepsFinal from "../Steps/StepsFinal";
import InitialSteps from "../Steps/InitialSteps";

// store
import reducer from "../Store/reducerStore";
import initialState from "../Store/initialState";
import * as actionName from "../Store/actionStore";
import { GlobalContext } from "views/App";

// create context Store
export const wizardContext = createContext();

const ContendModal = (props) => {
  const { onClose } = props;
  const [stateLocal, dispatch] = useReducer(reducer, initialState);
  const { state: globalState, dispatch: globalDispatch } = useContext(GlobalContext);
  const { user } = globalState;
  const [form] = Form.useForm();

  const { data, error, loading } = useQuery(patientByIdWizardMD, {
    variables: { id: user.id },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      const { newMedicalConditions, newDrugAllergies } = data.patientById;
      dispatch({
        type: actionName.SET_LOADINGGLOBAL,
        payload: false,
      });
      form.setFields([
        {
          name: "medicalconditions",
          value: newMedicalConditions,
          errors: null,
        },
      ]);
      form.setFields([
        {
          name: "drugAllergies",
          value: newDrugAllergies,
          errors: null,
        },
      ]);
    }
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
      dispatch({
        type: actionName.SET_STEPS,
        payload: null,
      });
    }
  }, [data, error, form]);

  const onFinishFormDetailsAppointments = (valuesForm) => {
    const { razónDeLaConsulta, sintomas, medicalconditions, drugAllergies } = valuesForm;
    dispatch({
      type: actionName.SET_MEDICALCONDITIONS,
      payload: medicalconditions,
    });
    dispatch({
      type: actionName.SET_DRUGALLERGIES,
      payload: drugAllergies,
    });
    dispatch({
      type: actionName.SET_SYMPTOMS,
      payload: sintomas,
    });
    dispatch({
      type: actionName.SET_REASON_APPOINTMENTS,
      payload: razónDeLaConsulta,
    });
    return dispatch({
      type: actionName.SET_STEPS,
      payload: stateLocal.stepsActive + 1,
    });
  };

  const RenderContendSteps = () => {
    const stepsActive = () => {
      switch (stateLocal.stepsActive) {
        case 0:
          return <Steps1 />;
        case 1:
          return (
            <Steps2
              key={uniqueId("Steps2_")}
              form={form}
              onFinishForm={onFinishFormDetailsAppointments}
            />
          );
        case 2:
          return <Steps3 />;
        default:
          return <StepsDefault />;
      }
    };

    return <Spin spinning={loading}>{stepsActive()}</Spin>;
  };

  return (
    <wizardContext.Provider value={{ state: stateLocal, dispatch: dispatch }}>
      <h2 style={{ color: "#727272" }} className='text-2xl'>
        <strong>Consulta inmediata</strong>
      </h2>
      {user ? (
        user?.plans.length || user?.beneficiaryOf ? (
          stateLocal.finishWizard ? (
            <StepsFinal />
          ) : (
            <>
              <RenderContendSteps />
              <div className='my-6 flex justify-center w-full mt-10'>
                <StepsMD className='flex justify-center w-1/2' />
              </div>
              <FooterModal
                user={user}
                globalDispatch={globalDispatch}
                onClose={onClose}
                form={form}
              />
            </>
          )
        ) : (
          <>
            <InitialSteps />
            <FooterModal
              user={user}
              globalDispatch={globalDispatch}
              onClose={onClose}
              form={form}
            />
          </>
        )
      ) : (
        <>
          <InitialSteps />
          <FooterModal user={user} globalDispatch={globalDispatch} onClose={onClose} form={form} />
        </>
      )}
    </wizardContext.Provider>
  );
};

export default ContendModal;
