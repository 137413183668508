import dotenv from "dotenv";
dotenv.config();
const NODE_ENV = process.env.REACT_APP_NODE_ENV || "development";
let URI = "";
if (NODE_ENV === "production") {
  URI = process.env.REACT_APP_APOLLO_URI_PROD || "http://localhost:302000/graphql";
} else {
  URI = process.env.REACT_APP_APOLLO_URI_DEV || "http://localhost:302000/graphql";
}
async function getVonageSessionToken(sessionId) {
  const data = JSON.stringify({
    query: `{  getVonageSessionToken(sessionId: "${sessionId || "invalid"}")}`,
  });
  try {
    var newToken = await fetch(URI, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: data,
    });
    newToken = await newToken.json();
    if (newToken?.errors) {
      alert("");
      return;
    }
    return newToken.data.getVonageSessionToken;
  } catch (error) {
    console.log("Error", error);
  }
}

export default getVonageSessionToken;
