import React, { useEffect, useState, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { XMPP } from "../xmpp";
import notification from "antd/lib/notification";
import { logoutUser, isLoginUser, isViewCitaOrOffline } from "./utils";
import uniqueId from "lodash/uniqueId";
import random from "lodash/random";

// components
import ModalAceptarLlamada from "./modalAceptarLlamada";

import NotificationMessage from "./notificacionMessage";

// Global Context
import { SET_CHATCALL_SESSIONID } from "../store/actions";
import { GlobalContext } from "../views/App";
import { CallChatContext } from "./store";
import { Modal } from "antd";

const ChatCallLayout = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const [listenerID, setListenerID] = useState(null);
  const [listenerMessageID, setListenerMessageID] = useState(null);
  const [incomingLlamada, setIncomingLlamada] = useState(false);
  const [metaDataCall, setMetaDataCall] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);
  const [presences, setPresences] = useState({});
  const history = useHistory();
  const [callStatus, setCallStatus] = useState(null);
  const [initListners, setInitListners] = useState(false);
  const [conflict, setConflict] = useState(false);
  const { user } = state;

  useEffect(() => {
    // en cada cambio de url, eliminar el listener de mensaje
    // y crearlo de nuevo si la url no es sala de espera (hacer un reset del listener)
    localStorage.setItem("location", props.location.pathname);
  }, [props.location]);

  // remover el listener al desmontar el componente
  useEffect(() => {
    createListenerID("CALL");
    createListenerID("MSG");

    return () => {
      terminarSessionLlamada();
      dispatch({
        type: SET_CHATCALL_SESSIONID,
        payload: null,
      });
      XMPP.removeCallListener(listenerID);
      XMPP.removeMessageListener(listenerMessageID);
      XMPP.removeCallListener();
      XMPP.removeMessageListener();
      logoutUser();
      XMPP.clearCache();
    };
  }, []);

  const createListenerID = (type = "CALL") => {
    const finalID = uniqueId(
      `Chat_ID_${type}_${random(10, 50).toString()}_${random(500, 9000).toString()}_`
    );
    if (type === "CALL") {
      setListenerID(finalID);
    } else {
      setListenerMessageID(finalID);
    }
  };

  /* la logica es que el notificacion de nuevo mensaje solo aparezca en vistas que no son la sala de espera */
  const messageListenerConfig = async () => {
    try {
      const isCitaView = isViewCitaOrOffline(props.location.pathname);
      if (!isCitaView) {
        const userChat = await isLoginUser();
        if (userChat) {
          listenerMensaje();
        }
      }
    } catch (e) {
      console.log("CCC?E");
    }
  };

  const terminarSessionLlamada = (idForClose = null) => {
    const sessionCancel = idForClose || state?.chatCall?.sessionID;
    if (sessionCancel) {
      XMPP.endCall(sessionCancel).then(
        (call) => {
          dispatch({
            type: SET_CHATCALL_SESSIONID,
            payload: null,
          });
        },
        (err) => {
          dispatch({
            type: SET_CHATCALL_SESSIONID,
            payload: null,
          });
        }
      );
    }
  };

  useEffect(() => {
    // si hay usuario logueado)
    if (user) {
      // obtener la uid guardada en el objeto del usuario logueado
      const openFireUsername = user?.openFireUsername;
      const openFirePassword = user?.openFirePassword;
      var loginResource = null;
      if (props.location.pathname.includes("cita")) {
        loginResource = props.location.pathname.split("/").pop();
      }
      setUpUserChat(openFireUsername, openFirePassword, loginResource);
    } else {
      resetVarsChat();
    }
  }, [user, props.location]);

  // callInProgress esta en true cuando se acepta la llamada desde la modal
  // metaDataCall obtiene data en el listener de llamadas, el usuario que solicita la llamada envia la metaData
  useEffect(() => {
    if (callInProgress && metaDataCall) {
      const { id: idAppointment, url } = metaDataCall;
      console.log(url, "URL");
      dispatch({
        type: SET_CHATCALL_SESSIONID,
        payload: {
          sessionID: "callInPogress",
        },
      });
      setMetaDataCall(null);
      setIncomingLlamada(false);
      setCallInProgress(false);
      history.push({
        pathname: url + idAppointment,
        state: {
          idAppointment: idAppointment,
        },
      });
    }
  }, [callInProgress]);

  const notificationNewMessage = (info) => {
    notification.destroy();
    notification.open({
      message: "Tienes un nuevo mensaje",
      duration: 0,
      description: <NotificationMessage info={info} redirect={redirectToSalaEspera} />,
    });
  };

  const redirectToSalaEspera = (url) => {
    notification.destroy();
    window.open(url);
  };

  const listenerMensaje = () => {
    const location = props.location.pathname;
    XMPP.addMessageListener(
      listenerMessageID,
      new XMPP.MessageListener({
        onTextMessageReceived: (textMessage) => {
          if (!isViewCitaOrOffline(location)) {
            notificationNewMessage(textMessage);
          }
        },
      })
    );
  };

  const listenerLlamada = () => {
    XMPP.addCallListener(
      listenerID,
      new XMPP.CallListener({
        onIncomingCallReceived(metaDataCall) {
          if (user?.id !== metaDataCall?._id) {
            try {
              metaDataCall.isNormalAppointment = JSON.parse(metaDataCall.isNormalAppointment);
            } catch (error) {}
            setMetaDataCall(metaDataCall);
            setIncomingLlamada(true);
            const metaData = {
              ...metaDataCall,
              chatWith: metaDataCall?.username,
            };
            XMPP.readyToAccept(metaData);
          }
        },
        onOutgoingCallAccepted(call) {
          setIncomingLlamada(false);
          setCallInProgress(true);
          setMetaDataCall(null);
        },
        onOutgoingCallRejected(call) {
          setIncomingLlamada(false);
          setCallInProgress(false);
          setMetaDataCall(null);
          dispatch({
            type: SET_CHATCALL_SESSIONID,
            payload: null,
          });
        },
        onIncomingCallCancelled(call) {
          setIncomingLlamada(false);
          setCallInProgress(false);
          setMetaDataCall(null);
          dispatch({
            type: SET_CHATCALL_SESSIONID,
            payload: null,
          });
        },
      })
    );
  };
  function onPresence(presence) {
    setPresences((presences) => {
      return { ...presences, ...presence };
    });
  }
  function onConflict() {
    setConflict(true);
  }
  useEffect(() => {
    if (Object.keys(presences)?.length && !initListners) {
      console.log("REGISTER Listerners", presences);
      messageListenerConfig();
      listenerLlamada();
      setInitListners(true);
    }
  }, [presences, initListners]);
  const setUpUserChat = async (username, password, loginResource = null) => {
    try {
      const userChat = await isLoginUser();
      if (!userChat) {
        resetVarsChat();
        await XMPP.init(username, password, onPresence, onConflict, loginResource);
      } else {
        // si hay usuario, pero el usuario de chatCall es distinto al del usuario de Easy_Md
        // desloguear y loguear con la id del usuario de Easy_Md
        const { _resource } = userChat;
        if (_resource !== loginResource) {
          resetVarsChat();
          await XMPP.logout();
          await XMPP.init(username, password, onPresence, onConflict, loginResource);
          messageListenerConfig();
          listenerLlamada();
        }
      }
    } catch (e) {
      logoutUser();
      resetVarsChat();
    }
  };

  const resetVarsChat = () => {
    XMPP.removeCallListener(listenerID); // local listener
    XMPP.removeMessageListener(listenerMessageID); // local listener
    setMetaDataCall(null);
    setIncomingLlamada(false);
    setCallInProgress(false);
    // dispatch({
    //   type: SET_CHATCALL_SESSIONID,
    //   payload: null,
    // });
  };

  const aceptarLlamada = async () => {
    const metaData = { ...metaDataCall, chatWith: metaDataCall?.username };
    await XMPP.acceptCall(metaData);
    setIncomingLlamada(false);
    setCallInProgress(true);
  };

  const cancelarLlamada = async () => {
    const metaData = { ...metaDataCall, chatWith: metaDataCall?.username };
    await XMPP.rejectCall(metaData);
    setMetaDataCall(null);
    setIncomingLlamada(false);
    setCallInProgress(false);
    dispatch({
      type: SET_CHATCALL_SESSIONID,
      payload: null,
    });
    setCallStatus("CALL_REJECTED");
    setCallStatus(null);
  };

  return (
    <CallChatContext.Provider value={{ presences, callStatus, setCallStatus }}>
      {incomingLlamada && (
        <ModalAceptarLlamada
          aceptarLlamada={aceptarLlamada}
          cancelarLlamada={cancelarLlamada}
          callInProgress={callInProgress}
          metaDataCall={metaDataCall}
        />
      )}
      <Modal
        visible={conflict}
        closable={false}
        onOk={() => {
          window.location.reload();
        }}
        onCancel={() => {
          window.open("about:blank", "_self");
          window.close();
        }}
        okText='Use Here'
        cancelText='Close'
      >
        <h2>You are logged in other window</h2>
      </Modal>
      {props.children}
    </CallChatContext.Provider>
  );
};

const ChatCallLayoutWithRouter = withRouter(ChatCallLayout);

export default React.memo(ChatCallLayoutWithRouter);
