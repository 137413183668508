import React, { useContext } from "react";
import { Link } from "react-router-dom";
// antd
import Button from "antd/lib/button";
// components
import BtnPdf from "./BtnPDF";
// context
import Context from "./Context";
// helpers
import { statusUsable } from "utils.js/statusColor";

export default function BtnAction() {
  const record = useContext(Context);
  const { id, isMD, usable, status, medic } = record;
  const { color, label, disabled } = statusUsable(isMD ? "BEFORE_APPOINTMENT" : usable);

  const props = {
    Btn: {
      disabled,
      size: "large",
      type: "primary",
      shape: "round",
      children: label,
      className: `float-right py-0 ${color}`,
      component: Button,
      to: {
        pathname: isMD
          ? `/cita/paciente/consultainmediata/${id}`
          : usable === "NOT_SCHEDULED"
          ? `md/appointment/checkout/${id}`
          : `/cita/paciente/llamada/${id}`,
      },
    },
  };

  if (status === "COMPLETE") {
    return medic ? <BtnPdf record={record} /> : null;
  }

  return <Link {...props.Btn} />;
}
