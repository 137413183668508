import React, { createContext, useReducer, useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "../apolloClient";
import ReactPixel from "react-facebook-pixel";
import { Howler } from "howler";

import initialState from "../store/initialState";
import reducer from "../store/reducer";

import RouteConfig from "../layouts/routeConfig";

import dotenv from "dotenv";

import "../styles/overrideAntd.less";
import "../styles/components.scss";

dotenv.config();

const hist = createBrowserHistory();
export const GlobalContext = createContext();

const PixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    Howler.stop();
    if (PixelId) {
      ReactPixel.init(PixelId);
      ReactPixel.pageView();
    }
  }, []);

  return (
    <GlobalContext.Provider value={{ state: state, dispatch: dispatch }}>
      <ApolloProvider client={client}>
        <Router history={hist}>
          <RouteConfig />
        </Router>
      </ApolloProvider>
    </GlobalContext.Provider>
  );
};

export default App;
